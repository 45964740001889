.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media print {
  @page {
    size: legal;
    margin: 0;
  }
  .page-break {
    clear: both;
    page-break-before: always !important;
  }
  body {
    margin: 1cm;
  }
  .noprint{
    display: none;
  }
  .no-print, .no-print *
  {
    display: none !important;
  }

  .resolved {
    display: none;
  }

  .ybug-launcher--bottom-right{
    display: none;
  }

  #lo-engage-ext-container {
    display: none;
  }

  .odeimage{
    width: 85% !important;
    margin: 10px 0px !important;
    height: 150px !important;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
