.ConfirmModal {
  & .ant-modal-close {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    border-bottom: 1px solid $accent-light-color;
    position: relative; }
  & .ant-modal-confirm-body {
    padding: 0.5rem 2rem;
    & .ant-modal-confirm-title {
      position: absolute;
      top: 20px; } }
  & .ant-modal-confirm-btns {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 1rem;
    border-top: 1px solid $accent-light-color; }
  & .ant-modal-body {
    padding: 0; } }
