.content-list-item {
  align-items: center;
  display: flex;
  flex-direction: row;
  width: 100%;
  border: 1px solid #704AED;
  border-radius: 5px;
  height: 50px;
  justify-content: space-between;
  padding-left: 15px;
  padding-right: 15px;

  div {
    display: flex;
    flex-direction: row;
    height: fit-content;
    align-items: center;

    p {
      margin-bottom: 0; } } }
