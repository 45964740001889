.comment-list {
  .ant-list-item {
    border-bottom: none !important;

    &.COMMENT, &.STATUS_CHANGE {
      justify-content: flex-start;
      .ant-list-item-meta {
        max-width: 3.5rem; } }

    &.file {
      padding-top: 0;
      .ant-space {
        padding-left: 3rem; } } } }
