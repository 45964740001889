.ant-card {
  &-bordered {
    border-radius: $card-border-radius;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);

    .ant-card-cover > div {
      border-top-left-radius: $card-border-radius;
      border-top-right-radius: $card-border-radius; } }

  &-actions {
    border-top: 1px solid $placeholder-background-color; }

  &-actions {
    position: absolute;
    width: 100%;
    bottom: 0px; }

  &-cover .empty-image {
    height: 160px;

    .circle {
      background-color: #ffffff;
      width: 100px;
      height: 100px;
      border-radius: 50%;
      text-align: center;
      font-size: 50px;
      line-height: 100px; } } }

.examples-item {
  .ant-card-body {
    padding: 7px; } }

.ant-descriptions-item.examples > span {
  display: block; }

.objective-item-header {
  width: 100%;

  .ant-space-item:last-child {
    width: 100%;

    .ant-space {
      width: 100%; } } }

// This styles fix the batch achievement list items wrong shrink and alignment
.achievement-item {
  display: flex;
  width: 100%;
  justify-content: space-between;

  > .ant-space-item {
    &:nth-child(1) {
      flex-grow: 3; }
    &:nth-child(2) {
      flex-grow: 1; } }

  .left-container {
    width: 100%;
    display: flex;
    flex-direction: row;

    .editable-label {
      align-items: flex-start; }

    > div {
      &:nth-child(1) {
        flex-grow: 1;
        display: flex;
        align-items: center; }
      &:nth-child(2) {
        flex-grow: 13; }
      &:nth-child(3) {
        flex-grow: 1;
        display: flex;
        align-items: center; }
      &:nth-child(4) {
        flex-grow: 1;
        display: flex;
        align-items: center; } }

    .ant-space .ant-space-item {
      &:nth-child(1) {
        flex-grow: 13; }
      &:nth-child(2) {
        flex-grow: 1; } } }

  .rigth-container {
    width: 100%; } }

.card-list-item {
  overflow: hidden;
  .ant-card-body {
    padding: 0 !important;
    .ant-card-meta {
      margin: 0 !important; } } // has -4 for default

  .ant-card-meta-title {
    margin-bottom: 0 !important;
    a {
      color: black; } } }
