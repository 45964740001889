.ant-btn {
  &-primary {
    background-color: $accent-color;
    border-color: $accent-color;

    &:hover, &active, &:focus {
      background-color: $accent-dark-color;
      border-color: $accent-dark-color; } }

  &-default {
    border-color: $accent-color;
    color: $accent-color;

    &:hover, &:active, &:focus {
      color: $accent-color;
      border-color: $accent-color;
      background-color: transparent; } }

  &-link {
    color: $accent-color;
    padding: 0;

    &:hover, &:active, &:focus {
      color: $accent-dark-color; } }

  &-grey {
    background-color: $text-color-grey;
    border: none;
    padding: 0;

    &:hover, &:active, &:focus {
      background-color: $text-color-grey;
      color: $text-color; } }

  &-background-ghost {
    color: $accent-color !important;
    border-color: $accent-color !important; } }

.ant-upload .ant-btn {
  &:active, &:hover, &:focus {
    color: $accent-color;
    border-color: $accent-color; } }

.secundary-btn {
  color: $accent-color;
  border: 1px solid $accent-color; }
