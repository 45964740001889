.notifications-list {
  width: 250px;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 300px;

  .ant-avatar {
    color: $accent-color;
    border: 1px solid $accent-color;
    background: $background-color; } }
