.empty-image {
  background-color: $placeholder-background-color;
  width: 100%;
  height: 200px;
  justify-content: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .anticon {
    font-size: 100px;
    padding: 0; } }

.circle {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 50%;
  padding: 5px;
  width: 70px;
  height: 70px;
  text-align: center;
  font-size: 30px; }

.batch-program {
  max-height: 123px;

  .empty-image {
    width: 150px;
    height: 100px; } }

.batch-summary {
  .empty-image {
    border-radius: 50%;
    width: 140px;
    height: 140px; } }

.batch-actions-bar {
  .ant-card-body {
    padding-bottom: 0 !important; } }

.wide-input {
  width: 100%; }

.admin-experts-list,
.batch-odes-list-item {
  padding: 1rem;
  > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    > div {
      width: 100%; } } }

.clickable:hover {
  cursor: pointer; }

.draggable:hover {
  cursor: grab; }

.isdragging:hover {
  cursor: grabbing; }

.assigned-expert-service-modal {
  .user-badge {
    margin: 10px 0; }

  .ant-avatar {
    color: #f7552b;
    margin-right: 15px;
    background-color: #fed8c1; } }

.input-tooltip {
  .ant-tooltip-inner {
    min-width: 280px; } }

.upload-dragger {
  background-size: cover !important;
  background-position: center center !important;
  background-repeat: no-repeat !important; }

.content-status-radio {
  align-items: center;
  background-color: white;
  border: 1px solid #5F39DE;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  height: 44px;
  width: 300px;
  .ant-radio-button-wrapper::before {
    display: none; }
  .ant-radio-button-wrapper {
    border: none;
    border-radius: 20px; } }

.panel-heading-form {
  .ant-form-item {
    margin-bottom: 0; } }

.create-content-modal {
  .ant-modal-header {
      padding: 0; } }

.content-modal {
  .ant-modal-header {
    padding: 0; }
  .video-player {
    display: flex !important;
    justify-content: center !important; } }

.entrepreneur-warning-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 400px;
  margin: auto;
  margin-top: 100px; }

.sidebar-menu {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden auto; }

.metrics-card {
  border-left: solid .5rem #000; }

.error-fallback-container {
  padding: 1rem;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center; }

.bold {
  font-weight: bold; }

.tag-expired {
  color: #CD3C52 !important;
  background: #fff0f2 !important;
  border-color: #CD3C52 !important; }

.OdeItem {
  & > .ant-card-body {
    padding: 0;
    & .ant-divider {
      margin: 0; }
    & .ant-space {
      padding: 1rem; } }
  & .ant-card-meta-detail {
      width: 100%; }
  & .OdeItem__Wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    & .ant-typography {
      margin: 0; }
    & button {
      border: none;
      padding: 0;
      & > span {
        font-size: 2rem; } }
    & .ant-space-item > button {
      padding-top: 10px;
      color: rgba(0, 0, 0, 0.65); } }
  & .OdeItem__Tags {
    gap: 4px;
    & .ant-tag {
      margin: 0; } }
  & .OdeItem__Avatars {
    display: flex;
    justify-content: flex-end;
    align-items: stretch; } }

.simple-tag {
  font-size: 10px; }

.droppable-element-mark {
  margin: 10px;
  height: 29px;
  width: 100%;
  opacity: 0.5;
  border-radius: 2px;
  background-color: #6348e5; }

.asem {
  ul.ant-menu {
    align-items: center;
    .asem__btn:hover {
      border-bottom-color: transparent !important; } }
  & .OrganizationIndex {
    & .ant-space {
      width: 100%; }
    &__controls {
      width: 80%;
      margin: 0 auto !important;
      padding: 1rem;
      & .ant-card-extra {
        width: 100%;
        float: none;
        margin: 0;
        & .ant-row {
          margin-bottom: 1rem; } }
      .ant-input-affix-wrapper,
      .ant-input {
        background-color: #F9F8FF;
        border: none; }
      .ant-select-selector {
        border: none; }
      .ant-input-affix-wrapper:focus {
        box-shadow: none; }
      .ant-select-focused {
        box-shadow: none; } }
    &__title {
      color: $accent-color;
      margin-bottom: 2rem; }
    &__map_environment_title {
      color: $accent-color;
      margin-bottom: .5rem; }
    &__list {
      .ant-card-body {
        padding: 0; }
      .ant-card-meta-title {
        margin: 0; }
      .ant-divider {
        margin: 0; }
      .ant-row {
        > div {
          margin-bottom: 1rem; } }
      .ant-col,
      .ant-list-item,
      .ant-card {
        height: 100%; } }
    &__space-title {
      display: flex;
      justify-content: space-between;
      padding: 1rem;
      .ant-typography {
        color: $text-color-grey-bold;
        margin: 0; }
      .ant-typography-secondary {
        font-size: 14px; } }
    &__space-body {
      width: 100%;
      padding: 1rem;
      .ant-typography {
        color: $text-color-grey-bold; } }
    &__space-footer {
      display: flex;
      justify-content: space-between;
      .anticon-ellipsis {
        font-size: 1.5rem; } } } }


.milestones__item--description {
  font-size: 14px;
  font-weight: 400;
  color: #8A939C; }

.TimeLine {
  h5.ant-typography {
    color: $accent-color;
    font-weight: 700; }
  span.ant-typography {
    color: $text-color; }
  span.anticon {
    color: $accent-color; } }

.TimeLineEE {
  height: 100%;
  .ant-card-body {
    max-height: 500px;
    overflow: hidden;
    overflow-y: scroll;
    h5.ant-typography {
      color: $accent-color;
      font-weight: 700; }

    .TimeLineEE-on-line {
      .ant-timeline-item-label {
        width: calc(50% - 60px) !important; }
      .ant-timeline-item-tail,
      .ant-timeline-item-head {
        left: 32% !important; }
      .ant-timeline-item-content {
        left: calc(50% - 50px) !important;
        width: calc(50% - -15px) !important;

        .clickable {
          margin-bottom: 10px; } } }

    .TimeLineEE-on-row {
      .ant-timeline-item-label {
        width: 20% !important; }
      .ant-timeline-item-tail,
      .ant-timeline-item-head {
        left: 22% !important; }
      .ant-timeline-item-content {
        left: 23% !important;
        width: 75% !important;

        .clickable {
          margin-bottom: 10px; } } } } }

.Statistic {
  display: block;
  margin: 2rem 0;
  .ant-statistic {
    border: 1px solid $accent-color;
    padding: 1rem; }
  .ant-statistic-title {
    color: $text-color;
    font-size: 1.75rem;
    font-weight: 700;
    margin: 0;
    span:first-child {
      margin-right: 0.75rem; }
    span {
      color: $text-color;
      font-size: 1rem;
      font-weight: 400; } }
  .ant-statistic-content-value {
    color: $placeholder-icon-color;
    font-size: 1rem;
    font-weight: 400; } }

.CompanyDetail {
  .ant-card-body {
    padding: 0; }
  .ant-space {
    width: 100%; }
  .ant-row:first-child {
    margin-bottom: 1rem;
    .ant-col:first-child {
      align-self: center; } }
  h3.ant-typography {
    margin: 0; }
  &__Wrapper-S {
    gap: 0 !important; }
  &__S1,
  &__S2,
  &__C1 {
    width: 100%;
    justify-content: space-between; }
  &__S1 {
    .ant-tag {
      border-color: #52C41A;
      border-radius: 10px;
      padding: 0 8px;
      background-color: #52C41A;
      color: #FFFFFF; } }
  &__S2 {
    .ant-typography {
      font-size: 12px;
      font-weight: bold;
      span:last-child {
        margin-left: 4px;
        color: #FFB61D; } } }
  &__S3 {
    .ant-space {
      gap: 0 !important;
      flex-wrap: wrap; }
    .ant-tag {
      margin-bottom: 4px; } }
  &__C1 {
    .ant-typography {
      font-size: 14px;
      font-weight: bold; } }
  &__C2 {
    .ant-typography {
      font-size: 14px;
      span:last-child {
        color: rgba(0, 0, 0, 0.45); } } }
  &__C3 {
    .ant-typography {
      font-size: 14px;
      span {
        margin-right: 4px;
        color: #000000; } } }
  &__C4 {
    margin-top: 1rem;
    .anticon {
      font-size: 28px;
      margin-right: 8px; } } }

.ant-descriptions-view {
  table {
    tbody {
      &:hover {
        cursor: default !important; }
      .ant-descriptions-row {
        &:hover {
          cursor: default !important; }
        cursor: default !important; } } } }

.CompanyGeneralInfo,
.CompanyAboutInfo {
  .ant-descriptions-row {
    .ant-descriptions-item {
      padding-bottom: 4px; } } }

.CompanyAboutInfo {
  &__Tags {
    .ant-descriptions-item-content {
      display: flex;
      flex-wrap: wrap;
      .ant-tag.ant-tag-default {
        margin-bottom: 8px; } } } }

.MonitoringExpert,
.OrganizationAsem__TabsPanes {
  .ant-tabs-nav-wrap {
    margin-top: 1rem; }
  .ant-tabs-tab.ant-tabs-tab-active {
    color: $accent-color;
    .ant-tabs-tab-btn {
      color: $accent-color; } }
  .ant-tabs-ink-bar {
    background: $accent-color; }
  .ant-tabs-tabpane {
    > .ant-space {
      display: flex;
      flex-wrap: wrap; } } }

.NewBatch {
  &__MembersForm {
    .ant-row.ant-row-space-between {
      align-items: center;
      .ant-form-item {
        flex: 1; }
      .ant-btn {
        margin-top: 5px;
        margin-left: 1rem;
        color: $accent-color;
        &:hover,
        &:focus {
          border-color: $accent-color; } } }
    .ant-list {
      .anticon.anticon-delete {
        font-size: 1.25rem;
        color: $accent-color; }
      .ant-list-item-meta-title {
        font-size: 1.2rem; }
      .ant-typography.ant-typography-secondary {
        font-size: 13px; } } }

  &__Publish {
    margin: 5px 13px !important;
    padding: 0;
    position: relative;
    > .ant-card-body {
      padding: 0; }
    &-EditBtn {
      position: absolute;
      top: 1.5rem;
      right: 1.5rem;
      z-index: 2; }
    &-Hero {
      padding: 2rem 1rem;
      background: rgba(95, 57, 222, 0.05);
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
      display: flex;
      justify-content: center;
      text-align: center;
      z-index: 1;
      .ant-card-body {
        width: 80%;
        padding: 1rem;
        .empty-image {
          width: 350px;
          height: 260px;
          border-radius: 30px;
          .circle {
            width: 150px;
            height: 150px;
            font-size: 80px; } } }
      h1.ant-typography {
        font-size: 52px;
        font-weight: 700;
        margin-top: 1rem; }
      h2.ant-typography {
        font-size: 30px;
        font-weight: 700; }
      .ant-typography {
        &.ant-typography-secondary {
          font-size: 20px; }
        &.ant-typography-success {
          font-weight: 500;
          font-size: 20px;
          color: $accent-color; } }
      .ant-btn-primary {
        width: 160px;
        border-radius: 10px;
        height: 50px;
        font-size: 18px; } }
    &-Learn {
      padding: 2rem 5rem 0 5rem;
      z-index: 0;
      h2.ant-typography {
        text-align: center;
        font-size: 32px;
        font-weight: 700;
        margin: 3rem 0; }
      &__Wrapper-List {
        .ant-card {
          margin-bottom: 3rem; } } }

    &-Team {
      padding: 0 5rem 2rem 5rem;
      text-align: center;
      h2.ant-typography {
        font-size: 32px;
        font-weight: 700; }
      .ant-typography-secondary {
        width: 350px;
        margin: 0 auto;
        font-size: 20px; }
      .ant-card-head {
        border: none; }
      &__Wrapper {
        width: 80%;
        margin: 2rem auto; } }

    .NewBatch__Tabs {
      margin-top: 3rem;
      & > .ant-tabs-nav {
        .ant-tabs-ink-bar {
          background-color: transparent; }
        &::before {
          border: none; }
        .ant-tabs-nav-wrap,
        .ant-tabs-nav-list {
          justify-content: center;
          .ant-tabs-tab {
            padding: 0.5rem 1rem;
            border-radius: 90px;
            background-color: transparent;
            border: 2px solid $text-color-grey;
            &:hover {
              background-color: rgba($accent-color, 0.9);
              & > .ant-tabs-tab-btn {
                color: $card-color; } }
            &-btn {
              color: $text-color; }
            &-active {
              background-color: $accent-color;
              > .ant-tabs-tab-btn {
                color: $card-color; }
              &:hover {
                background-color: $accent-color; } } } } } }

    .ExpertSection {
      margin-top: 2rem;
      margin-bottom: 4rem;
      display: flex;
      align-items: center;
      > .ant-space-item {
        width: 100%;
        padding: 0 4rem;
        .ant-card-body {
          h2.ant-typography {
            font-size: 32px;
            font-weight: 700; }
          .ant-typography-secondary {
            font-size: 20px;
            font-weight: 400; } } }
      .ant-tabs-nav {
        margin: 0 auto;
        .ant-tabs-ink-bar {
          background-color: $accent-color; }
        &::before {
          border: none; } }
      .ant-tabs-tab {
        &:hover {
          & > .ant-tabs-tab-btn {
            color: $accent-color; } }
        &-btn {
          font-size: 16px;
          color: $text-color;
          & .anticon {
            margin-right: 8px; } }
        &-active {
          > .ant-tabs-tab-btn {
            color: $accent-color; } } }
      .ant-tabs-content-holder {
        margin-top: 2rem;
        .ant-card-body {
          h2.ant-typography {
            font-size: 32px;
            font-weight: 700; }
          .ant-typography-secondary {
            font-size: 20px;
            font-weight: 400; } } }
      .ant-list-item {
        .ant-typography.ant-typography-secondary {
          font-size: 1rem; } }
      .ant-list-item-action {
        .ant-btn {
          color: $placeholder-icon-color;
          border-color: $placeholder-icon-color; } } } } }


.Card {
  &__Service.ant-card-bordered {
    box-shadow: none; }
  &__Service {
    .ant-card-head {
      background: #F5F3FD;
      &-title {
        font-size: 24px;
        font-weight: 700; } }
    .ant-card-body {
      padding: 24px;
      .ant-typography-secondary {
        margin: 0; } } } }

.OdeContentTab {
  width: 100%;
  .ant-tabs-nav {
    margin: 0 auto;
    .ant-tabs-ink-bar {
      background-color: $accent-color; }
    &::before {
      border: none; }
    .ant-tabs-tab {
      &:hover {
        & > .ant-tabs-tab-btn {
          color: $accent-color; } }
      &-btn {
        font-size: 16px;
        color: $text-color;
        & .anticon {
          margin-right: 8px; } }
      &-active {
        > .ant-tabs-tab-btn {
          color: $accent-color; } } } } }

.BatchHeroContent {
  margin: 2rem 0;
  width: 100%;
  .ant-typography {
    margin: 0;
    font-size: 32px;
    font-weight: bold; }
  &__Description {
    width: 600px;
    display: flex;
    justify-content: center;
    .ant-typography-secondary {
      font-size: 20px;
      font-weight: 400; } } }

.OdeNewList {
  .ant-card-body {
    h2.ant-typography {
      font-size: 32px;
      font-weight: 700; }
    .ant-typography-secondary {
      font-size: 20px;
      font-weight: 400; } } }

.OdeFormList {
  .ant-card {
    min-height: auto !important;
    .ant-card-body {
      padding: 0; } }
  .ant-row.ant-row-space-between {
    align-items: center;
    .ant-form-item {
      flex: 1; }
    .ant-btn {
      margin-top: 5px;
      margin-left: 1rem;
      color: $accent-color;
      &:hover,
      &:focus {
        border-color: $accent-color; } } } }

.CompanyServiceList {
  .ant-card-head {
    border: none;
    .ant-card-head-title {
      font-size: 20px;
      color: #595959;
      font-weight: 700; } }
  .ant-card-body {
    padding-top: 0;
    .ant-typography-danger,
    .ant-typography-success {
      padding: 4px 0; } } }

.SessionsExpert {
  > .ant-space {
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap; } }

.AlertError {
  &.ant-alert-error {
    border: 2px solid #FF4D4F;
    border-radius: 2px;
    display: flex; }
  .ant-alert-message {
    font-weight: 500; }
  .anticon {
    &.anticon-calendar.ant-alert-icon {
      display: block;
      padding: 4px;
      background-color: #FF4D4F;
      color: white;
      width: 34px;
      height: 34px;
      border-radius: 50%; }
    svg {
      width: 20px; } } }

.CommentListScheduled {
  .ant-list-item {
    justify-content: flex-start;
    border: none;
    .ant-list-item-meta {
      flex: none; } }
  &__Attachment {
    margin-top: 0.5rem; }
  &__Upload {
    display: flex;
    .ant-upload-list.ant-upload-list-custom {
      width: 100%; } } }

.OrganizationModal {
  &__wrapper {
    padding: 2rem; }
  &__title.ant-typography {
    font-weight: 700;
    color: #586472; }
  h3.ant-typography {
    margin: 0; }
  .ant-descriptions-title {
    font-weight: 600;
    font-size: 20px;
    color: #586472; }
  &__primary.ant-typography {
    color: #704AED;
    font-weight: 400; }
  .ant-typography.ant-typography-secondary,
  .ant-descriptions-item-label,
  .ant-descriptions-item-content {
    color: #586472;
    font-weight: 400; }
  .ant-descriptions-item-label {
    font-size: 16px; }
  &__icons {
    margin-top: 2rem;
    .anticon {
      font-size: 32px; } }
  .ant-modal-footer {
    padding: 1rem; }
  .ant-modal-title {
    font-size: 24px;
    color: #704AED;
    font-weight: 700; } }

.TeamBatch {
  ul.ant-list-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around; } }

.Team {
  width: 300px;
  margin-bottom: 3rem;
  &__Avatar {
    margin-bottom: 1rem;
    height: 365px;
    border-radius: 30px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; }
  h3.ant-typography {
    font-size: 28px;
    color: $accent-color;
    margin-bottom: 0; }
  h4.ant-typography {
    margin: 0.5rem 0; }
  .ant-typography-secondary {
    width: 100%;
    font-size: 14px;
    margin: 0;
    color: #000000; } }

.OrganizationAsem {
  padding: 1rem 4rem;
  &__title {
    color: $accent-color !important; } }

.MonitoringExpert {
  &__ExportButton {
    display: flex;
    button:first-child {
      width: 80%; }
    button:last-child {
      width: 20%; } }
  &__Actions {
    width: auto !important;
    min-width: auto !important; }
  &__Secondary {
    color: white;
    background-color: $accent-color;
    border-color: $accent-color;
    &:hover, &active, &:focus {
      color: white;
      background-color: $accent-dark-color;
      border-color: $accent-dark-color; } } }
