.BatchOffer {
  .batch-section-container {
    margin-top: 2rem;
    margin-bottom: 4rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding: 0 4rem;
    .ant-card-body {
      h2.ant-typography {
        font-size: 32px;
        font-weight: 700; }
      .ant-typography-secondary {
        font-size: 20px;
        font-weight: 400; } }
    .ant-tabs-nav {
      margin: 0 auto;
      .ant-tabs-ink-bar {
        background-color: $accent-color; }
      &::before {
        border: none; } }
    .ant-tabs {
      width: 100%; }
    .ant-tabs-tab {
      &:hover {
        & > .ant-tabs-tab-btn {
          color: $accent-color; } }
      &-btn {
        font-size: 16px;
        color: $text-color;
        & .anticon {
          margin-right: 8px; } }
      &-active {
        > .ant-tabs-tab-btn {
          color: $accent-color; } } }
    .ant-tabs-content-holder {
      margin-top: 2rem;
      .ant-card-body {
        h2.ant-typography {
          font-size: 32px;
          font-weight: 700; }
        .ant-typography-secondary {
          font-size: 20px;
          font-weight: 400; } } }
    .ant-list-item {
      .ant-typography.ant-typography-secondary {
        font-size: 1rem; } }
    .ant-list-item-action {
      .ant-btn {
        color: $placeholder-icon-color;
        border-color: $placeholder-icon-color; } } } }
