.ant-menu {
  &-submenu {
    &-title {
      span {
        color: $text-color; }

      &:hover {
        color: $accent-color;

        span {
          color: $accent-color; }

        .ant-menu-submenu-arrow {
          &::before, &::after {
            background: linear-gradient(to right, $accent-color, $accent-color); } } }

      &:active {
        background-color: $accent-light-color; } } }

  &-item {
    &:hover {
      color: $accent-color; }
    &:active {
      background-color: $accent-light-color; } } }
