#root {
  height: 100%; }

.ant-layout {
  &-sider-dark {
    background: #ffffff;
    border-right: 1px solid $border-color;

    .ant-menu-vertical {
      border-right: 0px; } }
  &-header > * {
    line-height: 1em; }

  .userbar {
    background-color: #ffffff;
    border-bottom: solid 1px $background-color;
    flex-direction: row;
    align-items: center;
    display: flex;

    > div {
      flex-direction: column;
      display: flex;
      align-items: flex-end;
      flex: 1 1; } }

  .main-content {
    background-color: $background-color; }

  &.onboarding-layout {
    background-color: $card-color;

    .main-content {
      background-color: $card-color; } } }

.ant-divider-horizontal.ant-divider-with-text::before {
  display: none; }

.ant-divider-with-text-left {
  .ant-divider-inner-text {
    padding: 0 1em 0 0; } }

.assigned-expert-service-form {
  .place {
    .ant-space-item {
      flex: 1;

      &:first-child {
        flex-grow: 0.1; } } }
  .programa,
  .batch,
  .hours {
    label::before {
      display: inline-block;
      margin-right: 4px;
      color: #ff4d4f;
      font-size: 14px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: '*'; } } }

.asem {
  &.Layout {
    .ant-select-selector {
      background-color: #F9F8FF !important; }
    .ant-layout-header {
      background: $accent-dark-color;
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding: 0;
      .ant-menu {
        line-height: normal;
        background: $accent-dark-color;
        & .ant-menu-item {
          vertical-align: baseline;
          border-bottom: 2px solid transparent;
          padding-top: 1rem;
          padding-bottom: 1rem; }
        .ant-menu-item:hover,
        .ant-menu-item-only-child.ant-menu-item-selected {
          border-bottom: 2px solid $border-color;
          background: none; }
        .ant-menu-item.Layout__btn:hover,
        .ant-menu-item-only-child.ant-menu-item-selected.Layout__btn {
          border-bottom: 2px solid transparent; } } } }

  .asem__OrganizationsEnvironmentMap-Table {
    .ant-card-body {
      padding: 0 !important; } }

  .asem__Tabs {
    & > .ant-tabs-nav {
      .ant-tabs-ink-bar {
        background-color: transparent; }
      &::before {
        border: none; }
      .ant-tabs-nav-wrap,
      .ant-tabs-nav-list {
        justify-content: center;
        .ant-tabs-tab {
          padding: 0.5rem 1rem;
          border-radius: 90px;
          background-color: transparent;
          border: 2px solid $text-color-grey;
          &:hover {
            background-color: rgba($accent-color, 0.9);
            & > .ant-tabs-tab-btn {
              color: $card-color; } }
          &-btn {
            color: $text-color; }
          &-active {
            background-color: $accent-color;
            > .ant-tabs-tab-btn {
              color: $card-color; }
            &:hover {
              background-color: $accent-color; } } } } } } }

.entrepreneur-spirit-timeline-modal {
  .ant-descriptions-view {
    .ant-descriptions-row {
      padding-bottom: 0 !important;
      .ant-descriptions-item {
        padding-bottom: 0 !important; } } }
  .ant-modal-body {
    padding: 24px 60px; }
  .ant-divider-horizontal {
    margin: 12px 0 12px 0; } }

.entrepreneur-spirit-impacted-community-table-card {
  .ant-card-body {
    height: 400px; } }
