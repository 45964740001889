$accent-color: #6348E5;
$accent-dark-color: #483AB5;
$accent-light-color: #EFEBFC;
$text-color: #222222;
$background-color: #F4F4F4;
$border-color: #EEEEEE;
$card-color: #FFFFFF;
$placeholder-icon-color: #A2A2A2;
$placeholder-background-color: #CCCCCC;
$card-border-radius: 5px;
$text-color-grey: #F0F0F0;
$text-color-grey-bold: #586472;
