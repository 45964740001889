/** @deprecated Legacy code **/
#login-form-container {
  display: flex;
  background-color: #FFF;
  height: 100%;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-direction: column; }

#privacy-policy-terms {
  text-align: center;
  margin-bottom: 1rem;
  margin-top: 1rem;
  font-size: 12px; }
